import React from 'react';
import Referrals from "../components/Referrals/Referrals";

const Referral = () => {
    return (
        <div>
            <Referrals/>
        </div>
    );
}

export default Referral;
