import React from 'react';
import Boosts from "../components/Boost/Boosts";

const Boost = () => {
    return (
        <div>
            <Boosts/>
        </div>
    );
};

export default Boost;